<template>
	<div class="browser">
		<div class="browser__top">
			<div class="browser__circle circle1"></div>
			<div class="browser__circle circle2"></div>
			<div class="browser__circle circle3"></div>
			<div class="browser__search"></div>
		</div>
		<div class="browser__screen">
			<Images width="100%" height="100%" align="top" v-if="image" :image="image" />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		image: String,
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/breakpoints.scss';

.browser{
	position: relative;
	padding-bottom: 60%;
	width: 100%;
}
.browser__top{
	@include breakpoint(sm){
		position: absolute;
		border: 2px solid #9F6F73;
		border-bottom: none;
		left: 10%;
		top: 0%;
		width: 80%;
		height: 9%;
		overflow: hidden;
		border-radius: 5px 5px 0 0;
	}
}
.browser__screen{
	overflow: hidden;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	border: 2px solid #9F6F73;

	@include breakpoint(sm){
		left: 10%;
		top: 9%;
		width: 80%;
		height: 90%;
	}
}
.browser__circle{
	@include breakpoint(sm){
		position: absolute;
		border: 2px solid #9F6F73;
		left: 10%;
		top: 50%;
		margin-top: -8px;
		width: 8px;
		height: 8px;
		border-radius: 50%;
	}
}
.circle1{
	left: 20px;
}
.circle2{
	left: 40px;
}
.circle3{
	left: 60px;
}
.browser__search{
	@include breakpoint(sm){
		position: absolute;
		border: 2px solid #9F6F73;
		right: 20px;
		top: 15%;
		width: calc(100% - 110px);
		height: calc(60% - 4px);
		border-radius: 4px;
	}
}
</style>

<!-- Used in : AR -->